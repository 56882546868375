<template>
    <div class="articles__article">

            <div class="articles__article_image">
                <h2>
                    <router-link :to="{path: '/review/' + this.review.slug}">Road test </router-link>
                </h2>
                <router-link :to="{path: '/review/' + this.review.slug}">
                    <img :src="imageUrl" width="100%" height="auto">
                </router-link>
            </div>

        <div class="articles__article_content">
            <h3><router-link :to="{path: '/review/' + this.review.slug}">{{ this.review.title }}</router-link></h3>
            <small>{{ formattedCreatedAtDate }}</small>
            <div class="overview" v-text="shortenText"></div>
            <router-link :to="{path: '/review/' + this.review.slug}" class="green">Read the full review</router-link>
        </div>
    </div>
</template>

<script>
    var moment = require('moment')
    export default {
        name: "CarReviewTile",
        props: ['review'],

        computed: {
            formattedCreatedAtDate() {
                return moment(this.review.created_at).format('Do MMMM YYYY');
            },

            imageUrl() {
                return this.review.thumbnail_large != null ? this.review.thumbnail_large : '';
            },

            shortenText() {
                if (this.review.overview_text && this.review.overview_text.length > 250)
                    return this.stripHtml(this.review.overview_text).substring(0, 250) + '...';
                else
                    return this.stripHtml(this.review.overview_text);
            }
        },
        methods:{
            stripHtml(html) {
                return html.replace(/<[^>]*>?/gm, '');
            }
        }
    }
</script>

<style scoped>

</style>
