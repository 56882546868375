<template>
    <div>
        <seo-meta></seo-meta>
        <main-header></main-header>
        <breadcrumbs></breadcrumbs>

        <div class="wrapper wrapper--grey wrapper--full">
            <div class="wrapper">

                <div class="article" v-if="review">

                    <div class="article__heading">
                        <h1 v-text="this.review.title"></h1>
                        <div class="stars stars--med">
                            <span v-for="star in numberStars" :key="star.id"></span>
                            <span class="stars__empty" v-for="star in (5-numberStars)" :key="star.id"></span>
                        </div>
                        <small>{{ numberStars }}/5</small>
                        <time>{{ formattedUpdatedAtDate }}</time>
                    </div>

                    <div class="grid grid--article">

                        <div class="article_flex_item">

                            <div class="article__content">

                                <div class="article__body">
                                    <div class="article__mainimage">
                                        <img width="808" height="504" :src="overviewImageUrl" alt="" />
                                        <p v-if="this.review.overview_image_caption != null">
                                            {{ this.review.overview_image_caption }}
                                        </p>
                                    </div>
                                    <div class="article__body_wrapper">
                                        <h2>Overview</h2>
                                        <div v-html="this.review.overview_text"></div>

                                        <div class="article__add2any">
                                            <div class="a2a_kit a2a_kit_size_32 a2a_default_style">
                                                <a class="a2a_dd" href="https://www.addtoany.com/share"></a>
                                                <a class="a2a_button_facebook"></a>
                                                <a class="a2a_button_twitter"></a>
                                            </div>
                                        </div>

                                        <div class="article__proscons">
                                            <div class="article__proscons_col">
                                                <small class="article__proscons_pros_title">Pros</small>
                                                <ul>
                                                    <li class="article__proscons_tick" v-for="(pro, index) in this.review.pros" :key="index">{{ pro.trait }}</li>
                                                </ul>
                                            </div>
                                            <div class="article__proscons_col">
                                                <small class="article__proscons_cons_title">Cons</small>
                                                <ul>
                                                    <li class="article__proscons_x" v-for="(con, index) in this.review.cons" :key="index">{{ con.trait }}</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <h2 v-if="this.review.exterior_text">
                                            Exterior
                                            <div class="stars stars--med">
                                                <span v-for="star in exteriorStars" :key="star.id"></span>
                                                <span class="stars__empty" v-for="star in (5-exteriorStars)" :key="star.id"></span>
                                            </div>
                                        </h2>
                                        <p v-html="this.review.exterior_text"></p>
                                        <div v-if="this.review.exterior_text" class="article__image">
                                            <img width="808" height="504" :src="exteriorImageUrl" alt="" />
                                            <p v-if="this.review.exterior_image_caption != null">
                                                {{ this.review.exterior_image_caption }}
                                            </p>
                                        </div>

                                        <h2 v-if="this.review.interior_text">
                                            Interior
                                            <div class="stars stars--med">
                                                <span v-for="star in interiorStars" :key="star"></span>
                                                <span class="stars__empty" v-for="star in (5-interiorStars)" :key="star.id"></span>
                                            </div>
                                        </h2>
                                        <p v-html="this.review.interior_text"></p>

                                        <div v-if="this.review.interior_text" class="article__image">
                                            <img width="808" height="504" :src="interiorImageUrl" alt="" />
                                            <p v-if="this.review.interior_image_caption != null">
                                                {{ this.review.interior_image_caption }}
                                            </p>
                                        </div>

                                        <h2 v-if="this.review.performance_text">
                                            Performance
                                            <div class="stars stars--med">
                                                <span v-for="star in performanceStars" :key="star.id"></span>
                                                <span class="stars__empty" v-for="star in (5-performanceStars)" :key="star.id"></span>
                                            </div>
                                        </h2>
                                        <p v-html="this.review.performance_text"></p>

                                        <div v-if="this.review.performance_text" class="article__image">
                                            <img  width="808" height="504" :src="performanceImageUrl" alt="" />
                                            <p v-if="this.review.performance_image_caption != null">
                                                {{ this.review.performance_image_caption }}
                                            </p>
                                        </div>

                                        <h2 v-if="this.review.reliability_text">
                                            Reliability
                                            <div class="stars stars--med">
                                                <span v-for="star in reliabilityStars" :key="star.id"></span>
                                                <span class="stars__empty" v-for="star in (5-reliabilityStars)" :key="star.id"></span>
                                            </div>
                                        </h2>
                                        <p v-html="this.review.reliability_text"></p>

                                        <div v-if="this.review.reliability_text" class="article__image">
                                            <img width="808" height="504" :src="reliabilityImageUrl" alt="" />
                                            <p v-if="this.review.reliability_image_caption != null">
                                                {{ this.review.reliability_image_caption }}
                                            </p>
                                        </div>

                                        <h2 v-if="this.review.running_costs_text">
                                            Running Costs
                                            <div class="stars stars--med">
                                                <span v-for="star in runningCostsStars" :key="star.id"></span>
                                                <span class="stars__empty" v-for="star in (5-runningCostsStars)" :key="star.id"></span>
                                            </div>
                                        </h2>
                                        <p v-html="this.review.running_costs_text"></p>

                                        <div v-if="this.review.running_costs_text" class="article__image">
                                            <img width="808" height="504" :src="runningCostsImageUrl" alt="" />
                                            <p v-if="this.review.running_costs_image_caption != null">
                                                {{ this.review.running_costs_image_caption }}
                                            </p>
                                        </div>

                                        <h2 v-if="this.review.handling_text">
                                            Handling
                                            <div class="stars stars--med">
                                                <span v-for="star in handlingStars" :key="star.id"></span>
                                                <span class="stars__empty" v-for="star in (5-handlingStars)" :key="star.id"></span>
                                            </div>
                                        </h2>
                                        <p v-html="this.review.handling_text"></p>

                                        <div v-if="this.review.handling_text" class="article__image">
                                            <img width="808" height="504" :src="handlingImageUrl" alt="" />
                                            <p v-if="this.review.handling_image_caption != null">
                                                {{ this.review.handling_image_caption }}
                                            </p>
                                        </div>

                                        <h2 v-if="this.review.safety_text">
                                            Safety
                                            <div class="stars stars--med">
                                                <span v-for="star in safetyStars" :key="star.id"></span>
                                                <span class="stars__empty" v-for="star in (5-safetyStars)" :key="star.id"></span>
                                            </div>
                                        </h2>
                                        <p v-html="this.review.safety_text"></p>

                                        <div v-if="this.review.safety_text" class="article__image">
                                            <img width="808" height="504" :src="safetyImageUrl" alt="" />
                                            <p v-if="this.review.safety_image_caption != null">
                                                {{ this.review.safety_image_caption }}
                                            </p>
                                        </div>

                                        <h3>Summary</h3>

                                        <div class="article__summary">
                                            <dl>
                                                <dt>Exterior</dt>
                                                <dd>
                                                    <div class="article__summary_bar">
                                                        <div class="article__summary_meter" :style="exteriorBar"></div>
                                                    </div>
                                                    <span>{{ formattedExterior }}%</span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>Interior</dt>
                                                <dd>
                                                    <div class="article__summary_bar">
                                                        <div class="article__summary_meter" :style="interiorBar"></div>
                                                    </div>
                                                    <span>{{ formattedInterior }}%</span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>Performance</dt>
                                                <dd>
                                                    <div class="article__summary_bar">
                                                        <div class="article__summary_meter" :style="performanceBar"></div>
                                                    </div>
                                                    <span>{{ formattedPerformance }}%</span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>Reliability</dt>
                                                <dd>
                                                    <div class="article__summary_bar">
                                                        <div class="article__summary_meter" :style="reliabilityBar"></div>
                                                    </div>
                                                    <span>{{ formattedReliability }}%</span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>Running Costs</dt>
                                                <dd>
                                                    <div class="article__summary_bar">
                                                        <div class="article__summary_meter" :style="runningCostsBar"></div>
                                                    </div>
                                                    <span>{{ formattedRunningCosts }}%</span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>Handling</dt>
                                                <dd>
                                                    <div class="article__summary_bar">
                                                        <div class="article__summary_meter" :style="handlingBar"></div>
                                                    </div>
                                                    <span>{{ formattedHandling }}%</span>
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>Safety</dt>
                                                <dd>
                                                    <div class="article__summary_bar">
                                                        <div class="article__summary_meter" :style="safetyBar"></div>
                                                    </div>
                                                    <span>{{ formattedSafety }}%</span>
                                                </dd>
                                            </dl>
                                        </div>

                                        <p v-html="this.review.summary"></p>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="article_flex_item article_flex_item--right">

                            <review-by-make modifier="searchbymake--all"></review-by-make>

<!-- MJH temp removed industry news -->
<!--                             <news-ctas></news-ctas>-->


<!--                            TODO disabled permanently - not going to add it soon-->
<!--                            <div class="banners">-->
<!--                                <div class="banners__inline">-->
<!--                                    <div class="banners__inline_item">-->
<!--                                        <img src="./img/photos/Layer 724.png.png" width="300" height="600" />-->
<!--                                    </div>-->
<!--                                    <div class="banners__inline_item">-->
<!--                                        <img src="./img/photos/Layer 728.png.png" width="120" height="600" />-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->

                        </div>

                    </div>
                </div>

            </div>

        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import numeral from 'numeral';

    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import Breadcrumbs from '@/components/partials/Breadcrumbs'
    import ReviewByMake from "../components/ReviewByMake"
    import SeoMeta from "../components/partials/SeoMeta"
    // MJH temp removed industry news
    //import NewsCtas from '@/components/partials/NewsCtas'

    // MJH temp removed from components below
    //            NewsCtas

    export default {
        name: "Review",
        data: function () {
            return {
                title: "Car Reviews, Reviews of Cars Sold in the Irish Market | Terrific.ie",
                description: "Car Reviews of cars available for sale in Ireland.",
                keywords: "Car Reviews, Reviews of Cars, Used Car Reviews, Electric Cars Reviewed, Audi Reviews, BMW Reviews, Ford Reviews, Toyota Reviews, 7 Seaters Reviewed, Land Rover Reviewed, People Carriers Reviewed",
                robots: "all"
            }
        },
        components: {
            ReviewByMake,
            MainHeader,
            MainFooter,
            Breadcrumbs,
            SeoMeta
        },

        computed: {
            formattedUpdatedAtDate() {
                return moment(this.review.updated_at).format('Do MMMM, YYYY');
            },

            overviewImageUrl() {
                return this.review.overview_image;
            },

            exteriorImageUrl() {
                return this.review.exterior_image;
            },

            interiorImageUrl() {
                return this.review.interior_image;
            },

            performanceImageUrl() {
                return this.review.performance_image;
            },

            reliabilityImageUrl() {
                return this.review.reliability_image;
            },

            runningCostsImageUrl() {
                return this.review.running_costs_image;
            },

            handlingImageUrl() {
                return this.review.handling_image;
            },

            safetyImageUrl() {
                return this.review.safety_image;
            },

            formattedExterior() {
                return numeral(this.review.exterior_pct).format('0');
            },

            formattedInterior() {
                return numeral(this.review.interior_pct).format('0');
            },

            formattedPerformance() {
                return numeral(this.review.performance_pct).format('0');
            },

            formattedReliability() {
                return numeral(this.review.reliability_pct).format('0');
            },

            formattedRunningCosts() {
                return numeral(this.review.running_costs_pct).format('0');
            },

            formattedHandling() {
                return numeral(this.review.handling_pct).format('0');
            },

            formattedSafety() {
                return numeral(this.review.safety_pct).format('0');
            },

            exteriorStars() {
                return this.review.exterior_pct != null ? Math.ceil(this.review.exterior_pct / 20) : 0;
            },

            interiorStars() {
                return this.review.interior_pct != null ? Math.ceil(this.review.interior_pct / 20) : 0;
            },

            performanceStars() {
                return this.review.performance_pct != null ? Math.ceil(this.review.performance_pct / 20) : 0;
            },

            reliabilityStars() {
                return this.review.reliability_pct != null ? Math.ceil(this.review.reliability_pct / 20) : 0;
            },

            runningCostsStars() {
                return this.review.running_costs_pct != null ? Math.ceil(this.review.running_costs_pct / 20) : 0;
            },

            handlingStars() {
                return this.review.handling_pct != null ? Math.ceil(this.review.handling_pct / 20) : 0;
            },

            safetyStars() {
                return this.review.safety_pct != null ? Math.ceil(this.review.safety_pct / 20) : 0;
            },

            exteriorBar() {
                return this.review.exterior_pct != null ? 'width: ' + this.review.exterior_pct + '%' : 'width: 0%';
            },

            interiorBar() {
                return this.review.interior_pct != null ? 'width: ' + this.review.interior_pct + '%' : 'width: 0%';
            },

            performanceBar() {
                return this.review.performance_pct != null ? 'width: ' + this.review.performance_pct + '%' : 'width: 0%';
            },

            reliabilityBar() {
                return this.review.reliability_pct != null ? 'width: ' + this.review.reliability_pct + '%' : 'width: 0%';
            },

            runningCostsBar() {
                return this.review.running_costs_pct != null ? 'width: ' + this.review.running_costs_pct + '%' : 'width: 0%';
            },

            handlingBar() {
                return this.review.handling_pct != null ? 'width: ' + this.review.handling_pct + '%' : 'width: 0%';
            },

            safetyBar() {
                return this.review.safety_pct != null ? 'width: ' + this.review.safety_pct + '%' : 'width: 0%';
            },

            numberStars() {
                let exteriorPct = this.review.exterior_pct != null ? this.review.exterior_pct : 0;
                let interiorPct = this.review.interior_pct != null ? this.review.interior_pct : 0;
                let performancePct = this.review.performance_pct != null ? this.review.performance_pct : 0;
                let reliabilityPct = this.review.reliability_pct != null ? this.review.reliability_pct : 0;
                let runningCostPct = this.review.running_costs_pct != null ? this.review.running_costs_pct : 0;
                let handlingPct = this.review.handling_pct != null ? this.review.handling_pct : 0;
                let safetyPct = this.review.safety_pct != null ? this.review.safety_pct : 0;

                let count = 0;
                if (this.review.exterior_pct != null) {
                    count++;
                }
                if (this.review.interior_pct != null) {
                    count++;
                }
                if (this.review.performance_pct != null) {
                    count++;
                }
                if (this.review.reliability_pct != null) {
                    count++;
                }
                if (this.review.running_costs_pct != null) {
                    count++;
                }
                if (this.review.handling_pct != null) {
                    count++;
                }
                if (this.review.safety_pct != null) {
                    count++;
                }

                let total =
                    parseInt(exteriorPct) +
                    parseInt(interiorPct) +
                    parseInt(performancePct) +
                    parseInt(reliabilityPct) +
                    parseInt(runningCostPct) +
                    parseInt(handlingPct) +
                    parseInt(safetyPct);
                return Math.ceil(total / count / 20);
            },

            review() {
                return this.$store.state.reviews.review[this.$route.params.slug]
            }
        },

        serverPrefetch() {
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
            return this.getReview();
        },

        mounted() {
            if (!this.review) {
                this.getReview();
            }
        },

        methods: {
            async getReview() {
                await this.$store.dispatch('reviews/fetchReview', this.$route.params.slug) // payload must be a string
                this.updateBreadcrumb();
            },
            updateBreadcrumb(){


                //If not found, redirect to 404 page
                if(typeof window !== 'undefined' && this.review == null){
                    return this.$router.push('/not-found');
                }

                this.$store.commit('breadcrumbs/setFirstLevelName',  'Car Reviews');
                this.$store.commit('breadcrumbs/setSecondLevelName',  this.review ? this.review.make.make : '');

                this.$store.commit('breadcrumbs/setSecondLevelName',  this.review ? this.review.make.make : '');
                this.$store.commit('breadcrumbs/setPathLevelOne',  '/car-reviews');
                var uri = this.review ? this.review.make.make : ''
                this.$store.commit('breadcrumbs/setPathLevelTwo',  '/car-reviews/' + uri);

                this.$store.commit('breadcrumbs/setLeafLevelName',  this.review ? this.review.title : '');

                let params = this.$router.currentRoute.params;

                if(typeof params.id !== 'undefined'){
                    this.$store.commit('breadcrumbs/setLevelOne',  true);
                    this.$store.commit('breadcrumbs/setLevelTwo',  true);
                    this.$store.commit('breadcrumbs/setLevelThree',  false);
                }else{
                    this.$store.commit('breadcrumbs/setLevelOne',  false);
                    this.$store.commit('breadcrumbs/setLevelTwo',  false);
                    this.$store.commit('breadcrumbs/setLevelThree',  false);
                }

            }
        }
    }
</script>

<style scoped>

</style>
