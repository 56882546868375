<template>
    <div>
        <main-header></main-header>
        <breadcrumbs></breadcrumbs>

        <div class="wrapper wrapper--grey wrapper--full">


            <div class="articles">
                <h1>Latest Car Reviews</h1>
                    <div class="grid grid--articles">

                        <div class="articles_flex_item articles_flex_item--right ios">
                            <review-by-make modifier="searchbymake--all" v-if="this.reviews"></review-by-make>
                            <!-- MJH temp removed industry news -->
                            <!-- <news-ctas></news-ctas>-->
                        </div>

                        <div class="articles_flex_item">

                            <div class="articles__articlecolumns" v-if="this.reviews">
                                <car-review-tile v-for="review in this.reviews.data" :key="review.id" :review="review"></car-review-tile>
                            </div>
                            <div class="pagination">
                                <pagination
                                    v-model="page"
                                    :records="this.$store.state.reviews.total"
                                    :options="paginationOptions"
                                    :per-page="parseInt(this.$store.state.reviews.perPage)"
                                    :vuex=true
                                    @paginate="pageChanged"
                                >
                                </pagination>
                            </div>
                        </div>

                        <div class="articles_flex_item articles_flex_item--right">
                            <review-by-make modifier="searchbymake--all" v-if="this.reviews"></review-by-make>
                            <!-- MJH temp removed industry news -->
                            <!-- <news-ctas></news-ctas>-->
                        </div>

                </div>
            </div>
        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>

    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import CarReviewTile from '@/components/partials/tiles/CarReviewTile'
    import ReviewByMake from '@/components/ReviewByMake'
    // MJH temp removed industry news
    //import NewsCtas from '@/components/partials/NewsCtas'
    import Pagination from 'vue-pagination-2'
    import {mapState,  mapMutations, mapActions} from 'vuex'
    import Breadcrumbs from '@/components/partials/Breadcrumbs';
    import ReviewsPagination from "@/components/paginator/ReviewsPagination";


    // MJH temp removed from components below
    //            NewsCtas

    export default ({
        name: "CarReviews",
        props: {
            make: {
                type: String,
                required: false
            },
            paramPage: {
                type: String,
                required: false
            }
        },
        components: {
            MainHeader,
            MainFooter,
            CarReviewTile,
            ReviewByMake,
            Pagination,
            Breadcrumbs
        },
        data: function () {
            return {
                title: "Car Reviews, Reviews of Cars Sold in the Irish Market | Terrific.ie",
                description: "Car Reviews of cars available for sale in Ireland.",
                keywords: "Car Reviews, Reviews of Cars, Used Car Reviews, Electric Cars Reviewed, Audi Reviews, BMW Reviews, Ford Reviews, Toyota Reviews, 7 Seaters Reviewed, Land Rover Reviewed, People Carriers Reviewed",
                robots: "all",
                routeName: this.$router.currentRoute.name,
                paginationOptions:{
                    template: ReviewsPagination,
                    edgeNavigation:true,
                    chunk:6,
                    chunksNavigation:'scroll',
                    texts:{
                        count:"Showing {from} to {to} of {count} reviews|{count} reviews|",
                        first:"First",
                        last:"Last"
                    }
                },
            }
        },
        metaInfo() {
            return {
                title: this.title,
            };
        },
        computed: {
            ...mapState([
                'resultsLoaded',
                'url',
                'searchResults',
                'searchOrderBy',
                'pagination',
                'showHideEmailAlert'
            ]),
            reviews() {
                return this.$store.state.reviews.reviews[typeof this.make !== 'undefined' ? this.make : this.page];
            },
            reviewMakes() {
                return this.$store.state.reviews.reviewMakes['all'];
            },
            currPage() {
                return typeof this.paramPage !== 'undefined' ? parseInt(this.paramPage) : 1;
            },
            page: {
                get() {
                    return this.$store.state.reviews.currentPage;
                },
                set(value) {
                    this.$store.commit('reviews/setCurrentPage', value);
                }
            },
        },
        watch: {
            $route() {
                this.getReviews();
            }
        },

        // Server-side only
        // This will be called by the server renderer automatically
        serverPrefetch() {
            this.$store.commit('reviews/setCurrentPage', this.currPage);
            this.getReviews();
            this.getReviewMakes();
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
            return false;
        },

        // Client-side only
        mounted() {
            this.$store.commit('reviews/setCurrentPage', this.currPage);
            if (!this.reviews) {
                this.getReviews();
            }else{
                this.$store.commit('reviews/setTotal', this.reviews.meta.total);
                this.$store.commit('reviews/setPerPage', this.reviews.meta.per_page);
                this.$store.commit('reviews/setCurrentPage', this.reviews.meta.current_page);
                this.$store.commit('reviews/setFrom', this.reviews.meta.from);
                this.$store.commit('reviews/setTo', this.reviews.meta.to);
            }

            if (!this.reviewMakes) {
                this.getReviewMakes();
            }
        },

        methods: {
            ...mapMutations([
                'setSearchMakeDesktop',
                'setSearchOrderBy',
                'setPagination',
                'setIsLoading',
                'setUrlParams',
                'setUrl',
                'resetSearchVars'
            ]),
            ...mapActions([
                'orderByChangeAction',
                'performSearchAction',
                'updateUrlAction',
                'performSearchAction'
            ]),
            pageChanged(){
                if(this.$router.currentRoute.path.match(/page\/(\d+)/)){
                    return this.$router.push({path: this.$router.currentRoute.path.replace(/page\/(\d+)/,'page/'+ this.page)});
                }else{
                    return this.$router.push({path: this.$router.currentRoute.path + '/page/' + this.page});
                }
            },
            async getReviewMakes() {
                await this.$store.dispatch('reviews/fetchReviewMakes', 'all');
            },
            async getReviews() {
                this.$store.commit('reviews/setCurrentPage', this.currPage);
                if (typeof this.make !== 'undefined' && this.make.length > 0) {
                    await this.$store.dispatch('reviews/fetchMakeReviews', this.make) // payload must be a string
                } else {
                    await this.$store.dispatch('reviews/fetchReviews', this.page) // payload must be a string
                }

                this.$store.commit('reviews/setTotal', this.reviews.meta.total);
                this.$store.commit('reviews/setPerPage', this.reviews.meta.per_page);
                this.$store.commit('reviews/setCurrentPage', this.reviews.meta.current_page);
                this.$store.commit('reviews/setFrom', this.reviews.meta.from);
                this.$store.commit('reviews/setTo', this.reviews.meta.to);
                this.updateBreadCrumb();
            },
            updateBreadCrumb(){

                this.$store.commit('setInternalSource',  false);

                this.$store.commit('breadcrumbs/setFirstLevelName',  "Car Reviews");
                this.$store.commit('breadcrumbs/setPathLevelOne',  '/car-reviews');

                this.$store.commit('breadcrumbs/setLevelTwo',  false);
                this.$store.commit('breadcrumbs/setSecondLevelName',  this.reviews.data[0].make.make.charAt(0).toUpperCase() + this.reviews.data[0].make.make.slice(1));
                this.$store.commit('breadcrumbs/setPathLevelTwo',  "/car-reviews/" + this.reviews.data[0].make.make);

                this.$store.commit('breadcrumbs/setLevelThree',  false);
                this.$store.commit('breadcrumbs/setThirdLevelName',  '');

                this.$store.commit('breadcrumbs/setLevelFour',  false);
                this.$store.commit('breadcrumbs/setFourthLevelName',  '');
            }
        }
    })
</script>

<style scoped>

</style>
