<template>
    <div class="searchbymake" :class="modifier">
        <h2>Car Reviews by Make</h2>
        <ul>
            <li v-for="reviewMake in reviewMakes" :key="reviewMake.id">
<!--                <router-link :to="{name: 'car-reviews', params:{make: reviewMake.make}}" replace>{{ reviewMake.make }}</router-link>-->
                <a :href="getLinkMake(reviewMake.make)" >{{ reviewMake.make }}</a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "ReviewByMake",
        props: ['modifier'],

        computed: {
            reviewMakes() {
                return this.$store.state.reviews.reviewMakes['all'];
            }
        },

        serverPrefetch() {
            return this.getReviewMakes();
        },

        mounted() {
            if (!this.reviewMakes) {
                this.getReviewMakes();
            }
        },

        methods: {
            async getReviewMakes() {
                await this.$store.dispatch('reviews/fetchReviewMakes', 'all');
            },
            getLinkMake(val){
                return '/car-reviews/' + val.toLowerCase();
            }
        }
    }
</script>

<style scoped>

</style>
